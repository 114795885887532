'use client'

import Button from '@components/interaction/button'
import type { Route } from 'next'
import { useHeader } from './header-provider'

interface HeaderLinkProps {
  className?: string
  title: string
  href: Route<string>
}

export default function HeaderLink({ title, ...props }: HeaderLinkProps) {
  const { isDark, isSmall, setOpen } = useHeader()

  const closeMenu = () => {
    const menuBtn = document.querySelector<HTMLInputElement>('#menu-btn')
    if (menuBtn != null) {
      menuBtn.checked = false
    }

    setOpen(false)
  }

  return (
    <Button
      colour={isDark && !isSmall ? 'secondary-contrast' : 'secondary'}
      onClick={closeMenu}
      variant='text'
      size='sm'
      {...props}
      label={title}
    />
  )
}
