'use client'

import Button from '@components/interaction/button'
import { usePathname } from 'next/navigation'

const darkRoutes = new Set(['/join'])

export default function ContactLink() {
  const pathname = usePathname()
  const isDark = darkRoutes.has(pathname)

  return (
    <Button
      variant='outlined'
      colour={isDark ? 'secondary-contrast' : 'secondary'}
      href='/contact'
      aria-label='Contact'
      size='sm'
      label='CONTACT'
    />
  )
}
